






















































































import { Component, Vue } from "vue-property-decorator";
import { PostBookContent } from "@/request/storehouse";
import { PostYuanwen } from "@/request/check";
import MainHeader from "@/components/main-header/main-header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends Vue {
  private id: any = "";
  private tijiaoData: any = {
    select: [],
    text: "",
  };
  private data: any = {};
  private ifShowSaveConfirm: any = false;
  private ifShowTijiao: any = false;
  private toPath: any = "";
  private saveType: any = "";
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get isElse() {
    const index = this.tijiaoData.select.indexOf("其它");
    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }
  private input(e: any) {
    const index = this.tijiaoData.select.indexOf("其它");
    if (index === -1) {
      this.tijiaoData.select.push("其它");
    }
  }
  private addTijiao() {
    this.saveType = "提交";
    this.tijiaoData = {
      select: [],
      text: "",
    };
    this.ifShowTijiao = true;
  }
  private saveTijiao() {
    this.saveType = "离开提交";
    this.tijiaoData = {
      select: [],
      text: "",
    };
    this.ifShowTijiao = true;
  }
  private tijiao() {
    if (this.tijiaoData.select.length == 0) {
      this.$message.warning("请先选择本次修改的内容再提交");
      return;
    }
    const index = this.tijiaoData.select.findIndex((v: any) => {
      return v === "其它";
    });
    let result: any = [];
    if (index === -1) {
      result = this.tijiaoData.select;
    } else {
      if (this.tijiaoData.text) {
        result = this.tijiaoData.select;
        result[index] = "其它，" + this.tijiaoData.text;
      } else {
        result = this.tijiaoData.select;
      }
    }
    const params: any = {
      title_id: this.id,
      status: "提交",
      text: result.join("、"),
      content: this.data.content,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostYuanwen(this, params)
      .then((res: any) => {
        this.$message.success("提交成功！");
        if (this.saveType == "提交") {
          this.back();
        } else {
          this.goDefault();
        }
        loading.close();
      })
      .catch(() => {
        loading.close();
      });
  }
  // 跳转目标页面
  private goDefault() {
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: this.toPath,
    });
  }
  private back() {
    this.$store.commit("updateIfCompose", false);
    this.$router.back();
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.ifCompose) {
      this.toPath = to.fullPath;
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private getData() {
    const params: any = {
      title_id: this.id,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostBookContent(this, params)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private save() {
    const params: any = {
      title_id: this.id,
      status: "保存草稿",
      content: this.data.content,
    };
    PostYuanwen(this, params).then((res: any) => {
      this.$message.success("保存草稿成功！");
      this.getData();
    });
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
  }
}
